@import '~react-image-gallery/styles/css/image-gallery.css';
body {
  margin: 0;
  overflow: hidden;
  font-family: 'Roboto', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.price-label {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: -1;
  margin-top: 6px;
  padding: 4px 8px;
  transform: translateX(-50%);
  border-radius: 3px;
  background: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}

.map-pin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin: -2px;
  border-radius: 50%;
  background: white;
}

.map-pin::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 12px;
  height: 12px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background: #4caf50;
}

.feature-list-item::before {
  content: '\25CF';
  position: relative;
  font-size: 1.3em;
  font-weight: bold;
  top: 25px;
}

.aiBanner {
  position: relative;
  height: 80px;
  background: linear-gradient(90deg, #2c81ff 0%, #af5af8 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 501;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 20px;
}
.aiBanner-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dismissAdBanner {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}
.dismissAdBanner img {
  width: 24px;
  height: 24px;
}
.carousel-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}
.carousel-item:first-child {
  display: flex;
}
.carousel-item.active {
  display: flex;
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 5px;
}
.carousel-indicator {
  height: 5px;
  width: 5px;
  margin: 0 5px;
  background-color: #fff;
  opacity: 0.5;
  color: transparent;
  border-radius: 50%;
  cursor: pointer;
}
.carousel-indicator.active {
  background-color: #fff;
  opacity: 1;
}
.learnMoreButton {
  margin-left: 20px;
  background-color: #2c81ff;
  color: #ffffff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 12px;
  font-weight: bold;
  border: 1px solid white;
}
@media (max-width: 400px) {
  .aiBanner {
    font-size: 10px;
  }
  .learnMoreButton {
    padding: 5px 10px;
    font-size: 12px;
  }
  .carousel-indicators {
    display: none;
  }
}
